import type { History } from 'history'
import { stringify as qsEncode } from 'query-string'
import { call, put, takeLatest } from 'redux-saga/effects'

import type {
  LoginResponse,
  Account as apiAccountType,
} from '@b-stock/account-api-client'
import { IdentityProviderType } from '@b-stock/account-api-client'
import { ddLogger, interpUri } from '@b-stock/bstock-react'

import accountApi from '@api/account'
import authApi from '@api/auth'
import signUpApi from '@api/signUp'
import sitesApi from '@api/sites'
import appConfig from '@config/config'
import type { AccountRoles, JWTAccessToken } from '@helpers/accessToken'
import {
  getDecodedAccessTokenWithoutRefresh,
  refreshAccessToken,
  RoleStatus,
} from '@helpers/accessToken'
import CookieManager from '@helpers/CookieManager'
import { getSignUpState } from '@helpers/getSignUpState'
import { gtag, gtagFields } from '@helpers/gtag'
import { sanitizeEmail } from '@helpers/sanitize'
import wait from '@helpers/wait'

import type { CheckAccessTokenPayload } from './actions'
import {
  accessTokenCheckFulFilled,
  accessTokenCheckRejected,
  accessTokenFulfilled,
  accessTokenPending,
  accessTokenRejected,
  fill,
  loaded,
  logoutFulfilled,
  logoutPending,
  logoutRejected,
  pending,
  pendingAccessToken,
  reject,
  reset,
  migrationPending,
} from './actions'
import type { LoginData } from './reducer'
import types from './types'

const { api: apiConfig } = appConfig

export enum AuthErrors {
  NoMarketplaces = 'No Marketplaces',
  DeniedMarketplaces = 'Denied Marketplaces',
  PendingMarketplaces = 'Pending Marketplaces',
  MigrationFailed = 'Migration Failed',
  MigrationTooSlow = 'Migration too slow',
}

export function* handleSiteStatus({ payload }: { payload: LoginData }): any {
  try {
    yield put(pending())
    // if (payload?._id || payload?.personId) {
    yield put(fill(payload))
    // } else {
    // yield call(authLogoutSaga, { authId: payload.accountId })
    // }
  } catch (error: any) {
    ddLogger.error('Error in handleSiteStatus', {}, error)
  }
}

export const setAuthCookies = (data: Partial<LoginResponse>) => {
  CookieManager.setCookie('bstock_access_token', data.access_token)
  CookieManager.setCookie('bstock_refresh_token', data.refresh_token)
  CookieManager.setCookie('bstock_ua_id', data.accountId)
}

/**
 * This Saga gets executed by OAuth2RedirectPage, which is where the user
 * lands after authenticating with an identity provider (currently FusionAuth
 * is the common case, with Salesforce being our identity provider for CSP
 * users)
 * Note: This saga has a few different points where it can stop early by
 * either throwing an exception that gets caught by the try/catch, or by
 * returning
 */
export function* authCodeSaga({
  identityProvider,
  payload,
  history,
}: {
  identityProvider: IdentityProviderType
  payload: { code?: string; state?: string }
  history: History
}): any {
  let accountRole = null
  let parsedState = null
  try {
    yield put(accessTokenPending())

    // Payload is pulled from the query parameters sent by identity provider
    // code = OAuth 2 authorization code which we'll exchange for access token
    // state = state 3mp sent to the identity provider, which then got echo'd
    //         back to 3mp (maybe manipulated along the way, so don't trust it)
    const { code, state } = payload
    if (!code) {
      // No code in query parameters means user is definitely not logged in.
      throw new Error('User not logged in.')
    }

    // We got a code - try to authenticate with it
    // (this will throw an AxiosError if the user can't log in)
    let loginResponse: LoginResponse
    if (identityProvider === IdentityProviderType.Fusionauth) {
      const { data } = yield call(authApi.faLogin, { code })
      loginResponse = data
    } else if (identityProvider === IdentityProviderType.Salesforce) {
      const { data } = yield call(authApi.sfLogin, { code })
      loginResponse = data
    } else {
      throw new Error(`Got unexpected identity provider: ${identityProvider}`)
    }

    // Parse the state data that we got from FusionAuth (if any)
    // Note: This state could have been manipulated, so don't trust it for
    // anything that the user won't be able to notice and correct later
    if (state) {
      try {
        parsedState = JSON.parse(state)
      } catch {
        // State isn't valid JSON. This is acceptable and can happen when
        // logging in from magento. Proceed with normal login/redirect logic as
        // if there were no state
      }
    }
    const siteAbb = parsedState?.siteAbb
    // emit google analytics event
    gtag({
      event: gtagFields.event.login,
      website_flow: gtagFields.website_flow,
      method: gtagFields.method.fusionAuth,
      ...(siteAbb ? { marketplace: siteAbb } : {}),
    })
    // Save the JWT tokens and accountId to the cookies
    setAuthCookies(loginResponse)

    let jwtData = getDecodedAccessTokenWithoutRefresh()
    const email = sanitizeEmail(jwtData.email)

    if (
      !jwtData.accountId &&
      !jwtData.personId &&
      identityProvider === IdentityProviderType.Fusionauth
    ) {
      // The user authenticated, but has no accountId or personId - they must
      // have a Magento account but no 3mp account. This should never happen
      // for anyone but users with FA as their ident provider but we check the
      // IP as this case with a non-FA IP would be an error.

      // Pull the marketplace access data from the Bridge service
      const checkMageExistsData: Awaited<
        ReturnType<typeof signUpApi.checkMageExists>
      > = yield call(signUpApi.checkMageExists, { email })

      // Log a summary of the user's enterprise site statuses
      const statusToSiteMap: Record<string, unknown> = {}
      checkMageExistsData.forEach((site) => {
        statusToSiteMap[site.status] = statusToSiteMap[site.status] ?? site
      })

      ddLogger.info(
        "User logged in, but doesn't have a 3MP account or person id.",
        {
          target: {
            siteAbb,
            email,
          },
          mageExists: statusToSiteMap,
        }
      )

      // Check if user approved on any Marketplace - if they are, let them in
      const approvedOnAny = checkMageExistsData.some(
        (site) => site.status === 'Approved'
      )
      if (!approvedOnAny) {
        // If they aren't approved, they need to wait for approval
        // The messaging we give them varies depending on if they are still
        // Pending or Denied on a Marketplace or not
        const pendingOnAny = checkMageExistsData.some(
          (site) => site.status === 'Pending'
        )
        if (pendingOnAny) {
          yield put(reject({ message: AuthErrors.PendingMarketplaces }))
        } else {
          const deniedOnAny = checkMageExistsData.some(
            (site) => site.status === 'Denied'
          )
          if (deniedOnAny) {
            yield put(reject({ message: AuthErrors.DeniedMarketplaces }))
          } else {
            yield put(reject({ message: AuthErrors.NoMarketplaces }))
          }
        }

        // The user never actually logged in - forget the access tokens so they
        // can try to log in later without issue
        // They'll stay authenticated in FusionAuth, so this shouldn't impact
        // them if they're actively using a Magento marketplace
        CookieManager.deleteAllCookies()
        return
      }

      // They were approved, so tell the bridge service to migrate their account
      const {
        status: migrateStatus,
      }: Awaited<ReturnType<typeof signUpApi.migrateMagentoUser>> = yield call(
        signUpApi.migrateMagentoUser
      )
      if (migrateStatus !== 202) {
        // Something went wrong and the migration wasn't started - bail out
        ddLogger.warn(
          "Call to migration endpoint didn't return the expected status code of 202. Erroring out of migration.",
          {
            migrateStatusCode: migrateStatus,
          }
        )
        yield put(reject({ message: AuthErrors.MigrationFailed }))

        // The user never actually logged in - forget the access tokens so they
        // can try to log in later without issue
        // They'll stay authenticated in FusionAuth, so this shouldn't impact
        // them if they're actively using a Magento marketplace
        CookieManager.deleteAllCookies()
        return
      }

      // Give some feedback to the user that their login is still processing,
      // it just might take longer
      yield put(migrationPending())
      let refreshCount = 0
      while (!jwtData.accountId && !jwtData.personId) {
        refreshCount++
        if (refreshCount > 100) {
          // The migration definitely shouldn't be taking this long... bail out
          // and let the user know they can try later
          ddLogger.warn(
            `Migration doesn't appear to have been finished after ${refreshCount} refresh attempts`
          )
          yield put(reject({ message: AuthErrors.MigrationTooSlow }))

          // The user never actually logged in - forget the access tokens so
          // they can try to log in later without issue
          // They'll stay authenticated in FusionAuth, so this shouldn't impact
          // them if they're actively using a Magento marketplace
          CookieManager.deleteAllCookies()
          return
        }
        // Avoid spamming the refresh by waiting a little before each check
        yield call(wait, 1000)
        yield call(
          refreshAccessToken,
          CookieManager.getCookie('bstock_access_token')!,
          CookieManager.getCookie('bstock_refresh_token')!
        )
        jwtData = getDecodedAccessTokenWithoutRefresh()
      }
      // We've got an accountId and personId now, so the migration must have at
      // least started... but give the back-end a little extra time to finish
      // the migration, just to be safe
      yield call(wait, 5000)
    }

    // Pull some 3mp account data out of the JWT token
    const { accountId, accountRoles: roles } = jwtData
    accountRole = roles[accountId]

    // Get the latest person data for the logged in user.
    // Most of the data we care about is already in loginResponse in the most
    // common case (the user already existed)...
    // but if the user needed to be migrated from Magento then loginResponse
    // wouldn't have had the necessary details yet.
    // To ensure we save the same details in the store no matter if the user
    // was or wasn't migrated already, re-fetch it from the person API
    // no matter what and save that information.
    const {
      data: person,
    }: Awaited<ReturnType<typeof authApi.accessTokenCheck>> = yield call(
      authApi.accessTokenCheck,
      jwtData.personId
    )

    // hot-fix get account data intead of token data due to isComplete race condition
    const account: Awaited<ReturnType<typeof accountApi.fetchAccount>> =
      yield call(accountApi.fetchAccount, { id: accountId })
    ddLogger.info('signing in with accountId: ', { accountId })
    // ZRO-2261
    // check jwt from sites obj to see if siteAbb from state exists in sites obj from token
    if (siteAbb) {
      yield call(addMarketplaceToAccount, {
        siteAbb,
        tokenData: jwtData,
        accountData: account,
        history,
      })
      return
    }

    yield put(
      accessTokenFulfilled({
        ...person,
        personId: jwtData.personId,
      })
    )
    void intelligentRedirect({ accountRole, parsedState, history })
  } catch (err: any) {
    ddLogger.error('Error in authCodeSaga', {}, err)
    if (err.response) {
      if (err.response.status === 409 && accountRole) {
        return intelligentRedirect({
          accountRole,
          parsedState,
          history,
        })
      }
    }
    const message = err.message || 'an error has occurred'
    yield put(accessTokenRejected(err))
    yield put(reject({ message }))
  } finally {
    yield put(loaded())
  }
}

/**
 * Exit the sign up process, cleaning up temporary state in the session.
 */
const navigateAwayTo = (url: string) => {
  sessionStorage.removeItem('BStock::AP::SignUp')
  window.location.assign(url)
}

/**
 * Send the user to the right place.
 * If they tried to access a private route, that route will be set in
 * `parsedState.redirectAfterLogin`. If they didn't, redirectAfterLogin will be
 * undefined and we either send them to seller portal (if they're a seller), to
 * the auctions list (if they're a buyer), or to onboarding if they're neither.
 */
export const intelligentRedirect = async ({
  accountRole,
  parsedState,
  history,
}: {
  accountRole: AccountRoles
  parsedState?: any
  history: History
}) => {
  const { redirectAfterLogin, redirectAfterSignup, siteAbb, subflow } =
    parsedState ?? {}
  // Warning: redirectAfterLogin (like everything else in parsedState) comes
  // from untrusted sources (the request query parameters), so don't use it
  // where it could pose a security risk (ex: window.location.assign) without
  // sanitizing/validating it first. history.push sanitizes the value for us,
  // so it is safe there (if the value wasn't a valid path, the user will just
  // see the 404 page)
  const redirectPath = redirectAfterSignup || redirectAfterLogin
  const isSeller = accountRole?.seller === RoleStatus.ACTIVE
  const isBuyer = accountRole?.buyer === RoleStatus.ACTIVE
  const isService = accountRole?.service === RoleStatus.ACTIVE

  if (!isSeller && !isBuyer && !isService) {
    // The user isn't an active seller or buyer or service user - assume they
    // haven't completed onboarding (for now)
    if (subflow === 'mao') {
      if (!redirectPath.startsWith('/buy/')) {
        history.push('/error')
        return
      }
      // MAO subflow skips profile creation - redirect to BP
      navigateAwayTo(redirectPath)
      return
    }
    const qs = qsEncode({ redirectAfterLogin, siteAbb })
    history.push(`/create-profile${qs ? `?${qs}` : ''}`)
    return
  }

  // Redirect to exact path if redirect string exists
  if (typeof redirectPath === 'string') {
    if (redirectPath.startsWith('/sell')) {
      // Redirect to seller portal if seller or service
      if (isSeller || isService) {
        navigateAwayTo(redirectPath)
        return
      }
    } else if (redirectPath.startsWith('/buy')) {
      // Redirect to buyer portal if buyer or service
      if (isBuyer || isService) {
        navigateAwayTo(redirectPath)
        return
      }
    } else if (redirectPath.startsWith('/csportal')) {
      // Redirect to cs-portal only if service
      if (isService) {
        navigateAwayTo(redirectPath)
        return
      }
    } else {
      // Account portal internal redirect
      history.push(redirectPath)
      return
    }
  }

  if (siteAbb) {
    const site = await sitesApi.getSite({ siteAbb })
    let siteUrl = site?.url
    if (!siteUrl) {
      throw new Error(`No site url found for ${siteAbb}`)
    }

    // Walmart (siteAbb `wal`) is unique among our sellers in that they have
    // their own subdomain. To work around cookie sharing complication that
    // creates we need to signal to the magento FE that the user is arriving
    // fresh out of the auth flow, so we append a param. This makes us sad,
    // but on the up side we can remove it once walmart is migrated to 3mp
    // buyer frontend. See ZRO-3360 for more details.
    if (['wal', 'wal-i'].includes(siteAbb)) {
      // Check if URL already has query parameters
      const separator = siteUrl.includes('?') ? '&' : '?'
      siteUrl += `${separator}loginRedir=1`
    }

    navigateAwayTo(siteUrl)
  } else if (isSeller) {
    navigateAwayTo('/sell')
    return
  } else {
    history.push('/settings')
  }
}

export async function addMarketplaceToAccount({
  siteAbb,
  tokenData,
  accountData,
  history,
}: {
  siteAbb: string
  history: History
  tokenData: JWTAccessToken
  accountData: apiAccountType
}) {
  let siteUrl = null
  let addMarketplaceDataResponse = null
  let parsedState = {}
  let accountRole = null
  try {
    const { url } = (await sitesApi.getSite({ siteAbb })) ?? {}
    const { sites } = accountData
    const { accountId, accountRoles } = tokenData
    accountRole = accountRoles[accountId as keyof AccountRoles]
    siteUrl = url
    if (siteAbb && sites?.[siteAbb] && siteUrl) {
      if (sites?.[siteAbb]?.isComplete) {
        parsedState = { siteAbb }
      } else {
        parsedState = {
          siteAbb,
          redirectAfterLogin: interpUri`/success?siteAbb=${siteAbb}`,
        }
      }
    } else {
      // ZRO-2262
      // if there's no siteAbb in the jwt's sites obj...
      addMarketplaceDataResponse = await sitesApi.addMarketplace({
        id: accountId,
        siteAbb,
      })
      // if statusCode is 201, add siteAbb to success page url and set as
      // redirectAfterLogin
      if (addMarketplaceDataResponse.status === 201) {
        // if the buyer is adding a marketplace BUT they have NOT completed
        // onboarding and their role is INTENDED, add the siteAbb to the
        // parsedState. The FE will save it in state when the user is redirected
        // to /create-profile
        parsedState = {
          redirectAfterLogin: interpUri`/success?siteAbb=${siteAbb}`,
          ...(accountRole.buyer === RoleStatus.INTENDED ? { siteAbb } : {}),
        }
        await refreshAccessToken(
          CookieManager.getCookie('bstock_access_token')!,
          CookieManager.getCookie('bstock_refresh_token')!
        )
        // if code is 409, site already exists in user's account, the FE should
        // check if isComplete is true
      }
    }

    return intelligentRedirect({ accountRole, parsedState, history })
  } catch (error: any) {
    ddLogger.error(
      'Error adding marketplace to account',
      { target: { siteAbb, accountId: tokenData.accountId } },
      error
    )
    if (error?.response?.status === 409 && siteUrl) {
      if (addMarketplaceDataResponse?.data?.isComplete) {
        parsedState = { siteAbb }
      } else {
        parsedState = { redirectAfterLogin: '/business-profile', siteAbb }
      }
    } else {
      parsedState = { redirectAfterLogin: '/error' }
    }
    if (accountRole) {
      return intelligentRedirect({ accountRole, parsedState, history })
    }
    history.push('/error')
    return
  }
}

export function* authLogoutSaga(): any {
  try {
    yield put(logoutPending())
    const { data } = yield call(authApi.logout)
    CookieManager.deleteAllCookies()
    yield put(logoutFulfilled(data))
    const { siteAbb } = getSignUpState()
    if (siteAbb) {
      const site = yield call(sitesApi.getSite, { siteAbb })
      const url = site?.url
      if (url) {
        window.location.assign(url)
        return
      }
    }
    window.location.assign(apiConfig.portalPaths.auctionsList)
    return
  } catch (err: any) {
    ddLogger.error('Error in authLogoutSaga', {}, err)
    yield put(logoutRejected(err))
  } finally {
    yield put(reset())
  }
}

export function* accessTokenCheckSaga({
  payload: { redirectToLogin, redirectAfterLogin, location },
  history,
}: {
  payload: CheckAccessTokenPayload
  history: History
}): any {
  try {
    yield put(pendingAccessToken())

    if (location && location.pathname === '/login') {
      yield put(loaded())
      yield put(accessTokenCheckFulFilled())
      return
    }

    let jwtData
    try {
      jwtData = getDecodedAccessTokenWithoutRefresh()
    } catch {
      ddLogger.warn(
        'accessTokenCheckSaga run with no access token (this is normal for unauthenticated users)'
      )
      const error = new Error('No token')
      yield put(accessTokenCheckRejected(error))
      if (redirectToLogin) {
        history.push(
          interpUri`/signin?redirectAfterLogin=${redirectAfterLogin}`
        )
      }
      return
    }

    // prevent magento user from coming back to accounts portal
    const { ua_id: uaId } = jwtData
    if (uaId) {
      yield put(accessTokenCheckFulFilled())
    }

    const {
      data: account,
    }: Awaited<ReturnType<typeof authApi.accessTokenCheck>> = yield call(
      authApi.accessTokenCheck,
      jwtData.personId
    )
    yield put(accessTokenCheckFulFilled())
    yield call(handleSiteStatus, {
      payload: {
        ...account,
        personId: jwtData.personId,
      },
    })
  } catch (error: any) {
    ddLogger.error('Error in accessTokenCheckSaga', {}, error)
    // cookies are not there/are expired
    if (redirectToLogin) {
      history.push(interpUri`/signin?redirectAfterLogin=${redirectAfterLogin}`)
    }
  } finally {
    yield put(loaded())
  }
}

export default function* loginSaga() {
  yield takeLatest(types.ACCESS_TOKEN_FULFILLED, handleSiteStatus as any)
  yield takeLatest(types.ACCESS_TOKEN, authCodeSaga as any)
  yield takeLatest(types.AUTH_LOGOUT, authLogoutSaga as any)
  yield takeLatest(types.ACCESS_TOKEN_CHECK, accessTokenCheckSaga as any)
}
