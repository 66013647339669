import type * as React from 'react'

import { styled } from 'styled-components'

import { Heading } from '@b-stock/bstock-react'

import FormattedMessage from '@helpers/FormattedMessage'

const SpinnerContainer = styled.div`
  text-align: center;
  padding: 30px;
`

export const LoadingText = styled(Heading)`
  margin-top: 12px;
  opacity: 0.15;
`

const BlockContainer = styled.div<{ $size: string }>`
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20%;
  margin: auto;
`

const SpinnerPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const colors = [
  'rgba(31, 96, 186, 0.15)',
  'rgba(31, 96, 186, 0.3)',
  '#1F60BA',
  'rgba(31, 96, 186, 0.7)',
]

const Block = styled.div<{ $set: number; $delay: number }>`
  @keyframes colorset1 {
    0% {
      background-color: ${() => colors[0]};
    }
    20% {
      background-color: ${() => colors[1]};
    }
    40% {
      background-color: ${() => colors[2]};
    }
    80% {
      background-color: ${() => colors[3]};
    }
    100% {
      background-color: ${() => colors[0]};
    }
  }

  @keyframes colorset2 {
    0% {
      background-color: ${() => colors[3]};
    }
    20% {
      background-color: ${() => colors[0]};
    }
    40% {
      background-color: ${() => colors[1]};
    }
    80% {
      background-color: ${() => colors[2]};
    }
    100% {
      background-color: ${() => colors[3]};
    }
  }

  @keyframes colorset3 {
    0% {
      background-color: ${() => colors[2]};
    }
    20% {
      background-color: ${() => colors[3]};
    }
    40% {
      background-color: ${() => colors[0]};
    }
    80% {
      background-color: ${() => colors[1]};
    }
    100% {
      background-color: ${() => colors[2]};
    }
  }

  @keyframes colorset4 {
    0% {
      background-color: ${() => colors[1]};
    }
    20% {
      background-color: ${() => colors[2]};
    }
    40% {
      background-color: ${() => colors[3]};
    }
    80% {
      background-color: ${() => colors[0]};
    }
    100% {
      background-color: ${() => colors[1]};
    }
  }

  animation: ${({ $set }) => `colorset${$set}`} ${({ $delay }) => `${$delay}ms`}
    linear infinite;
`

type SpinnerProps = {
  delay?: number
  size?: string
  text?: boolean
  className?: string
  children?: React.ReactNode
}
const Spinner = ({
  delay = 800,
  size = '50px',
  text = true,
  className,
  children,
}: SpinnerProps) => (
  <SpinnerContainer className={className}>
    <BlockContainer $size={size}>
      <Block $set={1} $delay={delay} />
      <Block $set={2} $delay={delay} />
      <Block $set={4} $delay={delay} />
      <Block $set={3} $delay={delay} />
    </BlockContainer>
    {text && (
      <LoadingText level={1}>
        <FormattedMessage id="loading" />
      </LoadingText>
    )}
    {children}
  </SpinnerContainer>
)

export default Spinner

export const SpinnerPage = () => (
  <SpinnerPageContainer>
    <Spinner />
  </SpinnerPageContainer>
)
