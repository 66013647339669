import { useSelector } from 'react-redux'

import GlobalStyle from '../../../GlobalStyle'

const Main = ({ children }: { children?: any }) => {
  const isLoggedIn = useSelector(({ auth }: any) => auth.login.isLoggedIn)

  return (
    <>
      <GlobalStyle isLoggedIn={isLoggedIn} />
      {children}
    </>
  )
}

export default Main
