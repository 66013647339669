import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  --fontSizeBase: 16px;
  --colorPrimary: ${({ theme }) => theme.colors.black};

  #app {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;
  }

  .no-display {
    display: none;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .noscroll {
    overflow: hidden;
  }

  html {
    font-size: 16px;
    font-family: 'Proxima Nova', 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.5rem;
  }

  button, input, optgroup, select, textarea {
    font-family: 'Proxima Nova', 'Roboto', sans-serif;
  }

  body {
    font-size: 16px;
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 0;
  }

  img {
    max-height: fit-content;
  }

  center, table, p, ul, ol {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  /* table styling */
  table {
    border: 0.0625rem solid ${({ theme }) => theme.colors.gray2};
    border-collapse: collapse;
  }

  th {
    font-weight: normal;
    padding: 0.7rem;
    background: ${({ theme }) => theme.colors.gray2};
  }
  td {
    vertical-align: baseline;
    padding: 0.7rem;
    font-weight: bold;
  }

  .hide_if_logged_in {
    ${({ isLoggedIn }: any) => isLoggedIn && 'display: none;'}
  }

  p {
    font-weight: normal;
  }
`

export default GlobalStyle
