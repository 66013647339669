import { yupToFormErrors } from 'formik'
import * as Yup from 'yup'

import './phone'
import './steps'
import './password'
import './postalCode'
import './signature'
import './file'
import './timeslot'
import './schedulingPolicy'

const defaultContext = { step: 1, errors: null }

export function validateSchema<T>(schema: Yup.AnySchema) {
  return async (values: T, { step, errors }: any = defaultContext) => {
    try {
      await schema.validate(values, {
        abortEarly: false,
        context: { step, errors },
      })
    } catch (e) {
      // if (process.env.NODE_ENV === 'test') {
      //   console.error(e)
      // }

      return yupToFormErrors(e)
    }

    return null
  }
}

Yup.setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    email: 'email',
  },
  array: {
    min: 'min',
    max: 'max',
  },
})

Yup.addMethod(Yup.string, 'notMatch', function (fieldName, message = 'match') {
  return this.when([fieldName], (value, schema) =>
    value?.length ? schema.notOneOf([Yup.ref(fieldName)], message) : schema
  )
})

type RequiredMethodProps =
  | string
  | {
      message?: string
      trim?: boolean
    }

Yup.addMethod(Yup.string, 'required', function (props: RequiredMethodProps) {
  const message =
    (typeof props === 'string' ? props : props?.message) || 'required'
  let schema: Yup.StringSchema = this.ensure()

  if (props?.trim ?? true) {
    schema = schema.trim()
  }

  return schema.test({
    message,
    name: 'required',
    test: (value) => !!value?.length,
  })
})

declare module 'yup' {
  interface StringSchema {
    notMatch(oldPassword: string, newPassword: string): Yup.StringSchema
  }
}
