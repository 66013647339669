import type * as React from 'react'

import { FormattedMessage as IntlMessage, useIntl } from 'react-intl'

type FormattedMessageProps = Omit<
  React.ComponentProps<typeof IntlMessage>,
  'id' | 'defaultMessage'
> & {
  id: string
  defaultMessage?: string
  common?: boolean
}

const FormattedMessage = ({
  id,
  defaultMessage,
  ...props
}: FormattedMessageProps) => (
  <IntlMessage id={id} defaultMessage={defaultMessage || id} {...props} />
)

type BaseProps = {
  baseId: string
  name?: string
  BaseComponent?: {
    baseId: string
    name: string
  }
}

const getFormatMessageProps = (
  baseProps: BaseProps | undefined,
  props: FormattedMessageProps
): FormattedMessageProps => {
  const { baseId = '', name = '' } = props.common
    ? {}
    : baseProps?.BaseComponent || baseProps || {}
  const id = baseId || name || ''

  return {
    ...props,
    id: `${id}${id ? '.' : ''}${props.id}`,
  }
}

export const useFormattedMessage = (baseProps?: BaseProps) => {
  const intl = useIntl()

  return {
    // This function is a mess. The type of "props" is super wonky. The correct
    // signature here should match `intl.formatMessage`, that is
    // `(descriptor: { id: string; defaultMessage?: string }, values: Record<string, ...>) => ...`.
    // But we can't change it without breaking a bunch of stuff. So we'll any
    // type it for now and kick the can until we get most the codebase typed.
    formatMessage: (props: any, options?: any) =>
      intl.formatMessage(
        getFormatMessageProps(baseProps, {
          ...(options || {}),
          id: props.id || props,
        }),
        props
      ),

    FormattedMessage: (props: FormattedMessageProps) => (
      <FormattedMessage {...getFormatMessageProps(baseProps, props)} />
    ),
  }
}

export { useIntl }
export default FormattedMessage
